body {
  /* position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: #333;
 
  overflow: hidden; */
}
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div,
header,
section,
footer {
  position: relative;
}

.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.layout {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  overflow-x: hidden;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #17644C, #63D0AE, #64D0AE);
}
header {
  position: relative;
  width: 100%;
  margin-bottom: 0.5vw;
}
.headerWrapper {
  position: relative;
  text-align: right;
  padding-right: 25vw;
  margin-bottom: 2vw;
}
.headerWrapper .logo {
  width: 4.9vw;
}
.banner {
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.banner .homeBg {
  position: absolute;
  left: 0;
  top: 1.4vw;
  z-index: 2;
  width: 39.3vw;
}
.banner .logo {
  position: absolute;
  left: 58vw;
  top: 2.7vh;
  z-index: 2;
  width: 23vw;
}
.banner .content {
  position: relative;
  z-index: 1;
  margin-top: 7vw;
  color: #391a08;
  background: #ffffff;
  border-radius: 3vw;
  margin-left: 8vw;
  text-align: right;
  padding: 7.4vw 8vw;
  padding-left: 35vw;
  padding-bottom: 2.6vw;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}
.banner .content .title {
  width: 30vw;
  position: relative;
  font-family: Arial Black;
  font-weight: 400;
  font-size: 2.5vw;
  color: #17644C;
  line-height: 3.5vw;
}
.banner .content .subtitle {
  position: relative;
  width: 38vw;
  margin-top: 4vw;
  font-family: Arial;
  font-weight: bold;
  font-size: 1.1vw;
  color: #8c8f9a;
  line-height: 2.3vw;
}
.banner .content .newDesc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5vw;
  color: #8c8f9a;
}
.banner .content .desc {
  position: relative;
  font-family: Arial;
  font-weight: bold;
  font-size: 1.1vw;
  color: #8c8f9a;
  line-height: 1.6vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.banner .content .line {
  font-size: 1.1vw;
  line-height: 1.6vw;
  margin: 0 0.5vw;
}
.banner .content .contentBg {
  width: 15vw;
}
.banner .content .banner-btn {
  width: 17.1vw;
  margin-top: 1vw;
  height: 4.6vw;
  line-height: 3vw;
  border: 0;
  font-size: 1.2vw;
  font-family: DIN Condensed;
  font-weight: 600;
  color: #1e7c51;
  opacity: 1;
  border-radius: 1vw;
}
.banner .content .banner-btn a {
  color: #1e7c51;
}
.banner .content .banner-btn .banner-img {
  margin-right: 0.5vw;
  width: 1.2vw;
  height: 0.9vw;
  margin-bottom: 0.3vw;
}
.banner .imgWrapper {
  z-index: 0;
  margin-top: 2.4vw;
  text-align: center;
}
.banner .imgWrapper img {
  width: 39vw;
}
.banner .word-download {
  width: 16.1vw;
  margin-top: 1.7vw;
  position: relative;
  cursor: pointer;
}
.banner-word-box {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.banner-word-downloads {
  margin-top: 30px;
  width: 237px;
  position: relative;
  height: 150px;
  color: #fff;
  display: flex;
}
.banner-word-downloads svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-downloads div {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 4;
  color: #fff;
  font-family: DIN Condensed;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.banner-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.block {
  position: relative;
  width: 100%;
}
.footer {
  position: relative;
  width: 100%;
  margin-top: 1vw;
  display: block;
}
.footer .bar {
  position: relative;
  overflow: hidden;
  padding: 0 1vw;
  color: #9c8c82;
}
.footer .barContent {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding-left: 35vw;
  padding-right: 18vw;
  text-align: right;
}
.footer .barContent .one-center {
  font-weight: 400;
  position: relative;
  font-family: Impact;
}
.footer .barContent .one-center .title {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 1.5vw;
  text-transform: uppercase;
  color: #cccccc;
  margin-bottom: 0.5vw;
}
.footer .barContent .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1vw;
  font-size: 1.4vw;
  color: #8c8f9a;
  font-size: 1.2vw;
  text-decoration: none;
  font-family: Arial;
  font-weight: bold;
}
.footer .barContent .one-center .infos .tips {
  margin-right: 10px;
}
.footer .barContent .one-center .infos a,
.footer .barContent .one-center .infos div {
  color: #8c8f9a;
  font-size: 1.2vw;
  text-decoration: none;
  font-family: Arial;
  font-weight: bold;
}
.footer .barContent .one-center .infos img {
  width: 1.4vw;
  margin-right: 0.5vw;
}
.footer .barContent .one-center .infos .linkEnd {
  margin-left: 1.2vw;
}
.footer .barContent .one-center .infos .email {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: initial;
}
.footer .barContent .one-center-end {
  text-align: left;
}
.footer .barContent .one-center-end .infos a {
  color: #000000;
  text-align: left;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 1.3vw;
  -webkit-text-decoration-color: #000 !important;
          text-decoration-color: #000 !important;
}
.footer .barContent .one-center-end .infos:last-child {
  margin-bottom: 0;
}
.footer .barIcon {
  width: 9.8vw;
}
.bottom-bar {
  position: relative;
  width: 100vw;
  padding: 1vw 0;
  text-align: center;
  background: #d6d6d6;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 1vw;
  color: #4d4d4d;
}

#reat-content {
  font-family: PingFang SC;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
body {
  margin: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.bodyMob {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  border: 0;
  margin: 0 auto;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  background: linear-gradient(to bottom, #17644C, #63D0AE, #64D0AE);
}
.bodyMob::-webkit-scrollbar {
  display: none;
}
.banner-mobile {
  position: relative;
}
.block-mobile {
  position: relative;
}
.header-mobile {
  position: relative;
}
header {
  height: 13.3vw;
  margin-bottom: 0;
}
.headerWrapper-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15.3vw;
  width: 100%;
  background-color: #FDEEC5;
}
.headerWrapper-mobile .logo {
  width: 9.6vw;
}
.headerWrapper-mobile .popover-menu {
  width: 300px;
}
.headerWrapper-mobile .popover-menu button.lang {
  margin: 4.2vw auto;
  float: none;
}
.headerWrapper-mobile .popover-menu div.version {
  margin: 8.5vw auto 4.2vw;
  float: none;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner {
  padding: 0;
  overflow: hidden;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner-content {
  padding: 0;
}
.headerWrapper-mobile .nav-phone-menu {
  fill: white;
  width: 5.3vw;
  height: 5.3vw;
  cursor: pointer;
}
.banner-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  overflow-x: hidden;
}
.banner-mobile .homeBg {
  position: absolute;
  left: 0;
  top: 8vh;
  z-index: 1;
  width: 100%;
  display: none;
}
.banner-mobile .homeHeader {
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 3;
}
.banner-mobile .logo {
  position: relative;
  width: 173px;
}
.banner-mobile .content {
  position: relative;
  z-index: 2;
  text-align: center;
  font-family: Impact;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.8);
  margin: 35px;
  border-radius: 23px;
  padding: 14px 35px;
  box-shadow: 0.5vw 0.5vw 1vw 0 rgba(0, 0, 0, 0.5);
}
.banner-mobile .content .title-mobile {
  font-size: 21px;
  text-align: center;
}
.banner-mobile .content .contentBg {
  width: 30vh;
  z-index: 0;
}
.banner-mobile .content .title {
  height: 25vh;
}
.banner-mobile .content .subtitle {
  position: relative;
  padding-left: 0vw;
  font-size: 12px;
  line-height: 24px;
  color: #333;
  text-align: center;
  font-family: Arial;
  font-weight: bold;
  margin: 3vw 0;
}
.banner-mobile .content .desc {
  padding-left: 1.1vw;
  padding-right: 1.1vw;
  font-size: 4vw;
  display: block;
  line-height: 6vw;
  text-align: left;
  text-transform: uppercase;
}
.banner-mobile .content .desc:first-child {
  padding-left: 0;
}
.banner-mobile .content .line {
  font-size: 4vw;
}
.banner-mobile .content .newDesc {
  display: flex;
  font-size: 10px;
  line-height: 24px;
  align-items: center;
  flex-direction: column;
  color: #999;
  font-family: Arial;
  font-weight: bold;
  border-radius: 15px;
  padding: 7px;
}
.banner-mobile .content .newDesc .desc {
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}
.banner-mobile .content .newDesc .line {
  font-size: 14px;
}
.banner-mobile .content .banner-btn {
  width: 60vw;
  position: relative;
  text-align: center;
  margin: 0 auto;
  border: 0;
  opacity: 1;
  border-radius: 1.2vw;
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.6vw;
  margin-bottom: 12vw;
  color: #FFFFFF;
}
.banner-mobile .content .banner-btn a {
  color: #1E7C51;
}
.banner-mobile .content .banner-btn .banner-img {
  width: 3.7vw;
  height: 3.7vw;
  margin-right: 0.3vw;
  margin-bottom: 0.7vw;
}
.banner-mobile .bannerBg {
  width: 90vw;
  margin: 6vw 0;
}
.banner-mobile .word-download {
  margin-top: 35px;
  height: 13vw;
}
.banner-mobile .word-download .point {
  height: 13vw;
  position: relative;
  right: 0;
  bottom: -3vw;
  margin-left: 2vw;
}
.banner-mobile .imgWrapper {
  position: relative;
  z-index: 0;
  margin-top: -35vh;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}
.banner-mobile .imgWrapper img {
  width: 100vw;
}
.banner-mobile-ims {
  position: absolute;
  left: -0.8vw;
  top: 0;
  width: 100%;
  height: 40vw;
  z-index: 3;
}
.banner-mobile-andriod {
  width: 4.5vw;
  height: 5.3vw;
  margin: -1.3vw 2.6vw 0 1.3vw;
}
.banner-mobile-newIcon {
  width: 12.5vw;
  height: 12.5vw;
  position: absolute;
  top: -4.2vw;
  right: -2.1vw;
  z-index: 999;
}
.banner-mobile-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.banner-mobile-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-mobile-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-mobile-text-wrapper {
  width: 40%;
  padding-right: 13.7vw;
  height: 70.6vw;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-mobile-text-wrapper h1 {
  font-size: 46px;
}
.banner-mobile-text-wrapper p {
  font-size: 4.2vw;
  margin: 13.3vw 0 16vw;
  font-weight: 300;
}
.block1-mobile {
  overflow: initial;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #1E213B 30%, #1E213B 70%), url(../../static/media/bg_feature.d20c76db.png);
}
.block1-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: left;
  padding: 8.3vw 7.1vw;
}
.block1-mobile .topTip h2 {
  font-size: 5.6vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 6.5vw;
  color: #7BDFFF;
}
.block1-mobile .topTip img {
  width: 40.5vw;
  height: 4.3vw;
}
.block1-mobile .topTip .title {
  width: auto;
  height: 3.2vw;
  margin-bottom: 1vw;
}
.block1-mobile .topTip div {
  padding-top: 0.8vw;
  font-size: 3.3vw;
  font-weight: 600;
  line-height: 4vw;
  color: #8587C4;
}
.block1-mobile .page-main-content {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 0 4.5vw;
  padding-top: 4.6vw;
  padding-bottom: 9.3vw;
  margin-top: -1px;
}
.block1-mobile .page-main-feature {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.block1-mobile .page-main-feature .item-feature .cardInfo {
  position: relative;
  border-radius: 2.6vw;
  color: #8587C4;
  background: rgba(255, 255, 255, 0.05);
  padding: 2vw;
  height: 30vh;
  margin-bottom: 5vw;
}
.block1-mobile .page-main-feature .item-feature .cardInfo .cardTitle {
  width: 29.4vw;
  font-size: 3.6vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 4.6vw;
  opacity: 1;
  color: #7BDFFF;
  margin: 2.8vw 0;
}
.block1-mobile .page-main-feature .item-feature .cardInfo .cardDetail {
  width: 28.5vw;
  font-size: 2.8vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.6vw;
  opacity: 1;
  margin-bottom: 5.3vw;
}
.block1-mobile .page-main-feature .item-feature .card0 {
  text-align: center;
}
.block1-mobile .page-main-feature .item-feature .card0 .cardDetail {
  line-height: 4.3vw;
  font-size: 2vw;
}
.block1-mobile .page-main-feature .item-feature .card1 {
  text-align: center;
}
.block1-mobile .page-main-feature .item-feature .card1 .cardDetail {
  font-size: 2vw;
  line-height: 4.3vw;
}
.block1-mobile .page-main-feature .item0 {
  margin-right: 4.3vw;
}
.block1-mobile .page-main-feature .item0 .cardIcon {
  width: 5.9vw;
  height: 10.2vw;
}
.block1-mobile .page-main-feature .item1 .cardIcon {
  width: 8vw;
  height: 10.2vw;
}
.block1-mobile .page-main-feature .item2 {
  margin-right: 4.3vw;
}
.block1-mobile .page-main-feature .item2 .cardIcon {
  width: 7vw;
  height: 10.2vw;
}
.block1-mobile .page-main-feature .item2 .cardInfo .cardDetail {
  line-height: 4.6vw;
}
.block1-mobile .page-main-feature .item3 .cardIcon {
  width: 10.9vw;
  height: 10.2vw;
}
.footer-mobile {
  position: relative;
  z-index: 3;
  width: 100%;
}
.footer-mobile .one-center {
  font-family: PingFang SC;
  font-weight: 600;
  position: relative;
  text-align: center;
  padding-right: 0;
}
.footer-mobile .one-center .title {
  font-size: 4.5vw;
  font-family: Arial;
  font-weight: bold;
  opacity: 1;
  color: #40424E;
  padding-bottom: 3.5vw;
}
.footer-mobile .one-center .infos {
  height: 6.4vw;
  opacity: 1;
  border-radius: 1.8vw;
  width: 58vw;
  margin: 0 auto;
  font-size: 3.4vw;
  font-family: Arial;
  line-height: 6.4vw;
  margin-top: 2.6vw;
  color: #8C8F9A;
}
.footer-mobile .one-center .infos a,
.footer-mobile .one-center .infos div {
  font-size: 3.3vw;
  line-height: 4.1vw;
  text-align: center;
  font-weight: 600;
  color: #FF9184;
  text-transform: uppercase;
  text-decoration: underline;
}
.footer-mobile .one-center-end {
  margin-top: 3.1vw;
  opacity: 1;
  text-align: center;
  padding: 6vw 0;
}
.footer-mobile .one-center-end .title {
  font-size: 4vw;
  font-family: Arial;
  font-weight: 600;
  line-height: 4vw;
  color: #8C8F9A;
  padding-bottom: 3.5vw;
}
.footer-mobile .one-center-end .infos {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8C8F9A;
}
.footer-mobile .one-center-end .infos div {
  line-height: 4vw;
  font-size: 3.4vw;
  font-family: Arial;
  font-weight: 600;
  text-decoration: none;
}
.footer-mobile .one-center-end .infos div img {
  width: 4vw;
  vertical-align: middle;
  margin-right: 0.5vw;
}
.footer-mobile .one-center-end .infos .contImg {
  position: relative;
  width: 3.7vw;
  height: 2.6vw;
  margin-right: 1.8vw;
  margin-top: 1vw;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #7BDFFF;
  color: #fff;
}
.ant-menu-item::after {
  border-right: 1vw solid #7BDFFF !important;
}
.bottom-barMobile {
  display: none;
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background-color: #f9f4f0;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: #f9f4f0;
  color: #f36a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #f36a5a;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #64d0ae;
  color: #fff;
  cursor: pointer;
}
.contentWrapper {
  padding: 20px 18vw;
  margin: auto;
  font-weight: bold;
  font-size: 14px;
  position: fixed;
  color: #000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 3vh;
  background-color: #eeeff3;
}
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  color: #40424e;
  text-transform: uppercase;
}
.contentWrapper h1 {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #40424e;
  text-transform: uppercase;
}
.contentWrapper p {
  position: relative;
  color: #40424e;
  text-align: left;
  text-indent: 30px;
  font-size: 18px;
  line-height: 30px;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    padding: 20px;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h2 {
    font-size: 24px;
    text-align: center;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 14px;
    line-height: 30px;
    white-space: pre-wrap;
  }
}
.checkModal {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.checkModal .modalContent {
  position: relative;
  overflow: hidden;
  width: 60%;
  border-radius: 0.9vw;
  background: #EEEFF3;
  margin: 0 3.5vh;
}
.checkModal .modalContent .listHead {
  background: #40424E;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  height: 6vw;
  line-height: 6vw;
  font-size: 3.2vh;
  font-family: Roboto;
}
.checkModal .modalContent .listHead .icon {
  width: 35px;
  height: 35px;
}
.checkModal .modalContent .list {
  position: relative;
  background-color: #EEEFF3;
  overflow: auto;
  text-align: center;
  max-height: 60vh;
  margin-bottom: 3vw;
  padding-top: 2vh;
  font-size: 2.8vw;
  padding: 2vw 2vw 0;
  line-height: 1.2;
}
.checkModal .modalContent .list .listItem {
  color: #40424E;
}
.checkModal .modalContent .list::-webkit-scrollbar-thumb {
  background-color: #eeeff3;
}
.checkModal .modalContent .ok {
  background-color: #64D0AE;
  font-family: Roboto;
  font-weight: 400;
  font-size: 2.1vw;
  color: #FFFFFF;
  border-radius: 60px;
  margin: 0 25px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 2vw;
  padding: 0.5vw 0;
}
.checkModal .modalContent .cancel {
  font-family: Roboto;
  font-weight: 400;
  font-size: 2.1vw;
  color: #8C8F9A;
  border-radius: 60px;
  margin: 0 25px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 2vw;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.select {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select .countrySelect {
  background-color: #fff;
  padding: 50px;
  border-radius: 21px;
}
.select .countrySelect .localCountry {
  position: relative;
  border-radius: 21px;
  padding: 10px;
  margin: 10px 14px;
  padding-left: 14px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #07d0e5;
}
.select .countrySelect .localCountry .countrylogo {
  width: 60px;
}
.select .countrySelect .localCountry .countryName {
  width: 200px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  padding-left: 0.5vw;
  padding-right: 1vw;
}

.countryTabs {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 1vw;
  cursor: pointer;
  z-index: 999;
  position: relative;
  font-size: 3vw;
  font-weight: bold;
  grid-gap: 4vw;
  gap: 4vw;
}
.countryTabs .tab1 {
  text-align: center;
  padding-right: 5vw;
  padding: 20px 4vw;
}
.countryTabs .tab1:hover {
  background-color: #64d0ae;
  color: #fff;
}
.countryTabs .tab2 {
  text-align: center;
  padding-left: 5vw;
  padding: 20px 4vw;
}
.countryTabs .tab2:hover {
  background-color: #64d0ae;
  color: #fff;
}
.countryTabs img {
  width: 30px;
}
.countryTabs .check {
  border-bottom: 2px solid #64d0ae;
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background-color: #f9f4f0;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: #f9f4f0;
  color: #f36a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #f36a5a;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #64d0ae;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper-mob {
  position: relative;
  min-height: 100vh;
  background: #eeeff3 !important;
  background-size: 120% auto;
  background-position: center;
  background-repeat: no-repeat;
}
.deleteWrapper-mob .tabNav {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 12vw;
  line-height: 12vw;
  z-index: 100;
  z-index: 0;
}
.deleteWrapper-mob .tabNav .title {
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  color: #40424e;
  letter-spacing: 0;
  font-size: 4vw;
  text-transform: uppercase;
}
.deleteWrapper-mob .content {
  position: relative;
  z-index: 1;
  border-radius: 0 0 2.8vw 2.8vw;
  padding: 0 5vw;
}
.deleteWrapper-mob .tip {
  font-size: 4.2vw;
  color: #40424e;
  font-weight: bold;
  text-align: left;
  padding: 2vw 0;
}
.deleteWrapper-mob .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vh;
  color: #40424e;
  text-align: left;
  padding: 0;
  margin-top: 7px;
}
.deleteWrapper-mob .fbtInfo {
  position: relative;
  opacity: 1;
  border-radius: 3.2vw;
  overflow: hidden;
  padding: 0.3vh 4.6vw;
  padding-top: 0;
}
.deleteWrapper-mob .fbtInfo .tip {
  text-align: center;
  font-size: 3.9vw;
  padding: 2.4vw 4.2vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  background: #9c8c82;
  letter-spacing: 0px;
  border-radius: 5vw;
  width: 50vw;
  margin: 0 auto;
}
.deleteWrapper-mob .fbtInfo .block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
  margin: 0 auto;
}
.deleteWrapper-mob .fbtInfo .block span {
  display: inline-block;
  width: 5vw;
  height: 4vw;
  background-color: #9c8c82;
}
.deleteWrapper-mob .fbtInfo .blocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55vw;
  margin: 0 auto;
}
.deleteWrapper-mob .fbtInfo .blocks span {
  display: inline-block;
  width: 5vw;
  height: 4vw;
  background-color: #9c8c82;
}
.deleteWrapper-mob .fbtCol {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper-mob .fbtCol .need {
  color: #e84f4f;
}
.deleteWrapper-mob .fbtPhone {
  background: rgba(245, 245, 247, 0);
  padding: 2.2vw 0;
  opacity: 1;
  width: 100%;
  border-radius: 4vh;
  background-color: #fff;
}
.deleteWrapper-mob .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  letter-spacing: 0px;
  margin-left: 3.2vw;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: left;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput {
  position: relative;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #40424e;
  color: #40424e;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.9vw;
  margin-left: 2.6vw;
  border: 0;
  text-align: left;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput:focus {
  outline: none;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #cccccc;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.9vw;
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::placeholder {
  color: #cccccc;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.9vw;
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpIdCard {
  padding-left: 4.6vw;
  width: 90%;
}
.deleteWrapper-mob .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
  padding-top: 1.2vw;
}
.deleteWrapper-mob .showTips span {
  font-size: 2.9vw;
  color: #f36a5a;
}
.deleteWrapper-mob .fbtPhone::-webkit-input-placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone::placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput {
  text-align: left;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput::-webkit-input-placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput::placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect {
  padding: 0 4.6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 4vh;
  opacity: 1;
  padding-top: 2.2vw;
  padding-bottom: 2.2vw;
  font-size: 3.9vw;
}
.deleteWrapper-mob .itemSelect .iSelect {
  border: 0;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  height: 5vw;
  line-height: 5vw;
  color: #391a08;
  width: 100%;
  text-align: center;
  background: initial;
  cursor: pointer;
}
.deleteWrapper-mob .itemSelect .iSelect:focus {
  outline: none;
}
.deleteWrapper-mob .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper-mob .itemSelect .iSelect::-webkit-input-placeholder {
  color: #f36a5a;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect .iSelect::placeholder {
  color: #f36a5a;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect .itemImg {
  width: 3.2vw;
  height: 2.8vw;
  cursor: pointer;
}
.deleteWrapper-mob .fbtContent {
  width: 100%;
  padding: 0 2.2vw;
  padding-top: 1vw;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  letter-spacing: 0px;
  opacity: 1;
  background: #fff;
  box-sizing: border-box;
  border-radius: 4vh;
}
.deleteWrapper-mob .fbtContent .fpLabel {
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper-mob .fbtContent .fpText {
  border: 0 !important;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  background-color: rgba(245, 245, 247, 0);
  text-align: left;
  caret-color: #f36a5a;
  width: 100%;
  max-height: 8vw;
  font-size: 3.9vw;
  line-height: 4.2vw;
  padding-left: 3.2vw;
}
.deleteWrapper-mob .fbtContent .fpText:focus {
  outline: none;
}
.deleteWrapper-mob .fbtContent .fpText::-webkit-input-placeholder {
  color: #f36a5a;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .fbtContent .fpText::placeholder {
  color: #f36a5a;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect::-webkit-input-placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect::placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper-mob .fbtContentOn {
  border: 2px solid #ffffff;
  opacity: 1;
}
.deleteWrapper-mob .fbtContentError {
  border: 2px solid #f36a5a;
}
.deleteWrapper-mob .idCard {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper-mob .agreeWrapper {
  margin: 0 3.2vw;
  margin-top: 5.9vw;
  margin-bottom: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.deleteWrapper-mob .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #40424e;
  width: 3.6vw;
  height: 3.6vw;
  border-radius: 5%;
}
.deleteWrapper-mob .agreeWrapper .hasCheck {
  border: 0;
}
.deleteWrapper-mob .agreeWrapper .hasCheck .laImg {
  position: absolute;
  top: 0;
  width: 3.6vw;
  height: 3.7vw;
}
.deleteWrapper-mob .agreeWrapper .laText {
  color: #40424e;
  letter-spacing: 0px;
  font-size: 3.5vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 4vw;
  width: 90%;
  text-align: left;
  cursor: pointer;
}
.deleteWrapper-mob .agreeWrapper .laText .normolColor {
  color: #40424e;
}
.deleteWrapper-mob .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #40424e;
  font-weight: bold;
  padding: 0 0.2vw;
}
.deleteWrapper-mob .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0vw;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #64d0ae;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin: 0 20vw;
  border-radius: 6.9vw;
  opacity: 0.7;
}
.deleteWrapper-mob .fbtActionMobile {
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #64d0ae;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin: 10vw 3vw 0;
  border-radius: 6.9vw;
  opacity: 0.7;
}
.deleteWrapper-mob .fbtActionOn {
  opacity: 1;
}
.deleteWrapper-mob .fbtSuccess {
  position: relative;
  display: flex;
  height: 100vh;
  margin: 0 auto;
  padding: 0 3.5vw;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0px;
  color: #64d0ae;
}
.deleteWrapper-mob .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor-mob {
  padding-top: 0;
  background-color: #f7f7f7;
  color: #40424e;
}
.deleteWrapperColor-mob .tabNav {
  height: 100vh;
}
.copySuccessTip-mob {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip-mob .iconsmile {
  width: 54px;
  height: 54px;
  fill: #40424e;
  margin-right: 9px;
}
.copySuccessTip-mob span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  opacity: 1;
}
.edit-mob .content .tip,
.edit-mob .content .desc {
  display: none;
}
.selectModal-mob .modalContent {
  border-radius: 0.9vw;
  margin: 0 4.6vw;
}
.selectModal-mob .modalContent .listHead {
  font-size: 3.9vw;
  height: 15vw;
}
.selectModal-mob .modalContent .list .listItem {
  font-size: 3.9vw;
  margin: 0 4.6vw;
  margin-bottom: 3vw;
}
.selectModal-mob .modalContent .list .listItem:last-child {
  border-bottom: 0;
}
.errorMsg-mob {
  font-size: 4.6vw !important;
  padding: 3.6vw !important;
}
.loader-mob {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.btn-mob {
  margin: 0 17vw;
  border-radius: 6.9vw;
  font-size: 4.6vw;
  padding: 1vw 4vw;
  margin-bottom: 3.5vw;
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background-color: #f9f4f0;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: #f9f4f0;
  color: #f36a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #f36a5a;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #64d0ae;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper {
  position: relative;
  padding: -5vh;
  min-height: 100vh;
  background: #eeeff3 !important;
  background-size: 120% auto;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 0 4vh;
}
.deleteWrapper .tabNav {
  position: relative;
  z-index: 1;
}
.deleteWrapper .tabNav .title {
  padding-top: 3.5vh;
  font-size: 2.6vh;
  font-family: Roboto;
  font-weight: bold;
  text-align: center;
  color: #40424e;
  text-transform: uppercase;
  letter-spacing: 0px;
}
.deleteWrapper .content {
  position: relative;
  z-index: 1;
  height: 85vh;
}
.deleteWrapper .tip {
  font-size: 2.6vh;
  color: #40424e;
  margin: 0.1vh 0;
  font-weight: bold;
  text-align: left;
  padding: 1vw 0;
}
.deleteWrapper .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.3vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.2vh;
  color: #40424e;
  text-align: left;
}
.deleteWrapper .fbtInfo {
  position: relative;
  opacity: 1;
  overflow: hidden;
}
.deleteWrapper .fbtInfo .tip {
  width: 20vw;
  background: #9c8c82;
  padding: 0.8vw 3vw;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.2vw;
  font-family: Roboto;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 0px;
  opacity: 1;
  border-radius: 5vw;
  margin: 0 auto;
}
.deleteWrapper .fbtInfo .block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 18vw;
  margin: 0 auto;
}
.deleteWrapper .fbtInfo .block span {
  display: inline-block;
  width: 2vw;
  height: 2vw;
  background-color: #9c8c82;
}
.deleteWrapper .fbtInfo .blocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55vw;
  margin: 0 auto;
}
.deleteWrapper .fbtInfo .blocks span {
  display: inline-block;
  width: 3vw;
  height: 2vw;
  background-color: #9c8c82;
}
.deleteWrapper .fbtCol {
  position: relative;
  padding-top: 1.4vh;
  padding-bottom: 1.2vh;
  font-size: 2.2vh;
  line-height: 2.5vh;
  font-family: Roboto;
  font-weight: 400;
  color: #40424e;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper .fbtCol .need {
  color: #e84f4f;
}
.deleteWrapper .fbtPhone {
  background: rgba(245, 245, 247, 0);
  padding: 1vh 0;
  opacity: 1;
  border-radius: 8vh;
  background: #fff;
}
.deleteWrapper .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.deleteWrapper .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  letter-spacing: 0px;
  margin-left: 2.2vw;
  font-size: 2vh;
  height: 2.2vh;
  line-height: 2.2vh;
  text-align: left;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 85%;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #40424e;
  color: #40424e;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 3.2vh;
  line-height: 3.2vh;
  font-size: 2vh;
  margin-left: 1.1vw;
  border: 0;
  text-align: left;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput:focus {
  outline-color: #fff;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  font-weight: 400;
  line-height: 3.2vh;
  text-align: left;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::placeholder {
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  font-weight: 400;
  line-height: 3.2vh;
  text-align: left;
}
.deleteWrapper .fbtPhone .fpInfo .fpIdCard {
  margin-left: 2.2vw;
  width: 90%;
}
.deleteWrapper .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
}
.deleteWrapper .showTips span {
  font-size: 2vh;
  color: #f36a5a;
}
.deleteWrapper .showTips img {
  margin-left: 2.2vw;
  width: 2vh;
  height: 2vh;
}
.deleteWrapper .fbtTop .fpInfo .fpInput {
  text-align: left;
}
.deleteWrapper .fbtTop .fpInfo .fpInput::-webkit-input-placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper .fbtTop .fpInfo .fpInput::placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper .fbtPhone::-webkit-input-placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper .fbtPhone::placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper .itemSelect {
  padding: 0 2.2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 8vh;
  opacity: 1;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 2vh;
}
.deleteWrapper .itemSelect .iSelect {
  border: 0;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  height: 3.2vh;
  line-height: 3.2vh;
  color: #391a08;
  width: 100%;
  background: initial;
  text-align: center;
  cursor: pointer;
}
.deleteWrapper .itemSelect .iSelect:focus {
  outline: none;
}
.deleteWrapper .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper .itemSelect .iSelect::-webkit-input-placeholder {
  color: #f36a5a;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect .iSelect::placeholder {
  color: #f36a5a;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect .itemImg {
  position: absolute;
  right: 2.5vw;
  width: 3.2vh;
  height: 2.8vh;
  cursor: pointer;
}
.deleteWrapper .fbtContent {
  width: 100%;
  padding: 1vw 2.2vw;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  background: #fff;
  border-radius: 8vh;
  box-sizing: border-box;
}
.deleteWrapper .fbtContent .fpLabel {
  font-size: 21px;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper .fbtContent .fpText {
  border: 0 !important;
  overflow: hidden;
  font-weight: bold;
  color: #40424e;
  background-color: rgba(245, 245, 247, 0);
  font-family: Roboto;
  caret-color: #40424e;
  text-align: left;
  width: 100%;
  max-height: 4.6vh;
  font-size: 2vh;
}
.deleteWrapper .fbtContent .fpText:focus {
  outline: none;
}
.deleteWrapper .fbtContent .fpText::-webkit-input-placeholder {
  color: #f36a5a;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .fbtContent .fpText::placeholder {
  color: #f36a5a;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect::-webkit-input-placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper .itemSelect::placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper .fbtContentOn {
  border: 2px solid #ffffff;
  opacity: 1;
}
.deleteWrapper .fbtContentError {
  border: 2px solid #f36a5a;
}
.deleteWrapper .idCard {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper .agreeWrapper {
  margin: 0 auto;
  margin-top: 3.2vh;
  margin-bottom: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.deleteWrapper .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #40424e;
  width: 2.6vh;
  height: 2.6vh;
  margin-left: 8px;
  margin-top: 2px;
}
.deleteWrapper .agreeWrapper .hasCheck {
  margin-left: 8px;
  margin-top: 2px;
  border: 0;
}
.deleteWrapper .agreeWrapper .hasCheck .laImg {
  position: absolute;
  left: 0;
  top: 0;
  height: 2.6vh;
}
.deleteWrapper .agreeWrapper .laText {
  color: #40424e;
  letter-spacing: 0px;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.1vh;
  width: 90%;
  text-align: left;
  cursor: pointer;
}
.deleteWrapper .agreeWrapper .laText .normolColor {
  color: #40424e;
}
.deleteWrapper .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #40424e;
  font-weight: bold;
  padding: 0 0.2vw;
}
.deleteWrapper .fbtAction {
  position: absolute;
  left: 0;
  bottom: 1vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
  background: #64d0ae;
  height: 4.2vw;
  opacity: 0.7;
  font-size: 2.2vw;
  color: #ffffff;
  letter-spacing: 0px;
  margin: 0;
  border-radius: 3vw;
  cursor: pointer;
}
.deleteWrapper .fbtActionOn {
  opacity: 1;
}
.deleteWrapper .fbtSuccess {
  position: relative;
  display: flex;
  height: 90vh;
  z-index: 100;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 4.8vh;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0px;
  color: #64d0ae;
}
.deleteWrapper .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor {
  background-color: #40424e;
  color: #40424e;
  padding-top: 0;
}
.copySuccessTip {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip .iconsmile {
  width: 54px;
  height: 54px;
  fill: #40424e;
  margin-right: 9px;
}
.copySuccessTip span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  opacity: 1;
}
.edit {
  height: auto;
  padding-bottom: 40vh;
}
.list {
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
}
.list .am-list-content {
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 2.1vh !important;
  padding-top: 1.2vh !important;
  padding-bottom: 1.2vh !important;
}
.list .am-radio-input {
  height: 5vw;
}
.list .am-list-item {
  min-height: 4vh !important;
}
.list .am-radio {
  height: 4vh !important;
}
.list .am-radio-inner {
  right: 0.5vh !important;
  top: 2vw !important;
  width: 2.2vh;
  height: 2.2vh;
}
.list .am-radio-inner:after {
  width: 1.2vh;
  height: 2.2vh;
}
.list .listItem {
  text-align: center;
}
.list .listItemTip {
  font-size: 2.1vh;
  color: #999999;
  text-align: center;
}
.list .listItemTip .listItemTSign {
  color: #b92d00;
}
.selectModal {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.loader {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.errorMsg {
  font-size: 2.2vh;
  padding: 3.3vh;
}
.btn {
  margin: 0 8vh;
  border-radius: 6.9vh;
  font-size: 2.2vh;
  padding: 1vh 4vh;
  margin-bottom: 3.5vh;
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background-color: #f9f4f0;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: #f9f4f0;
  color: #f36a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #f36a5a;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #64d0ae;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper-mob {
  position: relative;
  min-height: 100vh;
  background: #eeeff3 !important;
  background-size: 120% auto;
  background-position: center;
  background-repeat: no-repeat;
}
.deleteWrapper-mob .tabNav {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 12vw;
  line-height: 12vw;
  z-index: 100;
}
.deleteWrapper-mob .tabNav .title {
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  color: #40424e;
  letter-spacing: 0;
  font-size: 4vw;
  text-transform: uppercase;
}
.deleteWrapper-mob .content {
  position: relative;
  z-index: 1;
  border-radius: 0 0 2.8vw 2.8vw;
  padding: 0 5vw;
}
.deleteWrapper-mob .content .tip {
  font-size: 4.2vw;
  color: #40424e;
  margin: 7px 0;
  font-weight: bold;
  text-align: left;
  padding: 2vw 0;
  position: relative;
}
.deleteWrapper-mob .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vh;
  color: #40424e;
  text-align: left;
  padding: 0;
  margin-top: 7px;
}
.deleteWrapper-mob .fbtInfo {
  position: relative;
  opacity: 1;
  border-radius: 3.2vw;
  overflow: hidden;
  padding: 0.3vh 0;
}
.deleteWrapper-mob .fbtInfo .tip {
  text-align: center;
  font-size: 3.9vw;
  padding: 2.4vw 4.2vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  background: #9c8c82;
  letter-spacing: 0px;
  border-radius: 5vw;
  width: 50vw;
  margin: 0 auto;
}
.deleteWrapper-mob .fbtInfo .block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
  margin: 0 auto;
}
.deleteWrapper-mob .fbtInfo .block span {
  display: inline-block;
  width: 5vw;
  height: 4vw;
  background-color: #9c8c82;
}
.deleteWrapper-mob .fbtInfo .blocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55vw;
  margin: 0 auto;
}
.deleteWrapper-mob .fbtInfo .blocks span {
  display: inline-block;
  width: 5vw;
  height: 4vw;
  background-color: #9c8c82;
}
.deleteWrapper-mob .fbtCol {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper-mob .fbtCol .need {
  color: #e84f4f;
}
.deleteWrapper-mob .fbtPhone {
  background: rgba(245, 245, 247, 0);
  padding: 2.2vw 0;
  opacity: 1;
  width: 100%;
  border-radius: 4vh;
  background-color: #fff;
  margin-bottom: 3vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  letter-spacing: 0px;
  margin-left: 3.2vw;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: left;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput {
  position: relative;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #40424e;
  color: #40424e;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.9vw;
  margin-left: 2.6vw;
  border: 0;
  text-align: left;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput:focus {
  outline: none;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #cccccc;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.9vw;
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::placeholder {
  color: #cccccc;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.9vw;
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpIdCard {
  padding-left: 4.6vw;
  width: 90%;
}
.deleteWrapper-mob .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
  padding-top: 1.2vw;
}
.deleteWrapper-mob .showTips span {
  font-size: 2.9vw;
  color: #f36a5a;
}
.deleteWrapper-mob .fbtPhone::-webkit-input-placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone::placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput {
  text-align: left;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput::-webkit-input-placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput::placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect {
  padding: 0 4.6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 4vh;
  opacity: 1;
  padding-top: 2.2vw;
  padding-bottom: 2.2vw;
  font-size: 3.9vw;
  margin-bottom: 3vw;
}
.deleteWrapper-mob .itemSelect .iSelect {
  border: 0;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  height: 5vw;
  line-height: 5vw;
  color: #391a08;
  width: 100%;
  text-align: center;
  background: initial;
  cursor: pointer;
}
.deleteWrapper-mob .itemSelect .iSelect:focus {
  outline: none;
}
.deleteWrapper-mob .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper-mob .itemSelect .iSelect::-webkit-input-placeholder {
  color: #f36a5a;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect .iSelect::placeholder {
  color: #f36a5a;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect .itemImg {
  width: 3.2vw;
  height: 2.8vw;
  cursor: pointer;
}
.deleteWrapper-mob .fbtContent {
  width: 100%;
  padding: 0 2.2vw;
  padding-top: 1vw;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  letter-spacing: 0px;
  opacity: 1;
  background: #fff;
  box-sizing: border-box;
  border-radius: 4vh;
}
.deleteWrapper-mob .fbtContent .fpLabel {
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper-mob .fbtContent .fpText {
  border: 0 !important;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  background-color: rgba(245, 245, 247, 0);
  text-align: left;
  caret-color: #f36a5a;
  width: 100%;
  max-height: 8vw;
  font-size: 3.9vw;
  line-height: 4.2vw;
  padding-left: 3.2vw;
}
.deleteWrapper-mob .fbtContent .fpText:focus {
  outline: none;
}
.deleteWrapper-mob .fbtContent .fpText::-webkit-input-placeholder {
  color: #f36a5a;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .fbtContent .fpText::placeholder {
  color: #f36a5a;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect::-webkit-input-placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect::placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper-mob .fbtContentOn {
  border: 2px solid #ffffff;
  opacity: 1;
}
.deleteWrapper-mob .fbtContentError {
  border: 2px solid #f36a5a;
}
.deleteWrapper-mob .idCard {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper-mob .agreeWrapper {
  margin: 0 3.2vw;
  margin-top: 5.9vw;
  margin-bottom: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.deleteWrapper-mob .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #40424e;
  width: 3.6vw;
  height: 3.6vw;
  border-radius: 5%;
}
.deleteWrapper-mob .agreeWrapper .hasCheck {
  border: 0;
}
.deleteWrapper-mob .agreeWrapper .hasCheck .laImg {
  position: absolute;
  top: 0;
  width: 3.6vw;
  height: 3.7vw;
}
.deleteWrapper-mob .agreeWrapper .laText {
  color: #40424e;
  letter-spacing: 0px;
  font-size: 3.5vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 4vw;
  width: 90%;
  text-align: left;
  cursor: pointer;
}
.deleteWrapper-mob .agreeWrapper .laText .normolColor {
  color: #40424e;
}
.deleteWrapper-mob .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #40424e;
  font-weight: bold;
  padding: 0 0.2vw;
}
.deleteWrapper-mob .fbtAction {
  position: fixed;
  left: 0;
  bottom: 28vh;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #64d0ae;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin: 0 20vw;
  border-radius: 6.9vw;
  opacity: 0.7;
}
.deleteWrapper-mob .fbtActionMobile {
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #64d0ae;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin: 10vw 3vw 0;
  border-radius: 6.9vw;
  opacity: 0.7;
}
.deleteWrapper-mob .fbtActionOn {
  opacity: 1;
}
.deleteWrapper-mob .fbtSuccess {
  position: relative;
  display: flex;
  height: 100vh;
  margin: -20vw auto;
  padding: 0 3.5vw;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0px;
  color: #64d0ae;
}
.deleteWrapper-mob .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor-mob {
  padding-top: 0;
  background-color: #f7f7f7;
  color: #40424e;
}
.copySuccessTip-mob {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip-mob .iconsmile {
  width: 54px;
  height: 54px;
  fill: #40424e;
  margin-right: 9px;
}
.copySuccessTip-mob span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  opacity: 1;
}
.edit-mob .content .tip,
.edit-mob .content .desc {
  display: none;
}
.selectModal-mob .modalContent {
  border-radius: 0.9vw;
  margin: 0 4.6vw;
}
.selectModal-mob .modalContent .listHead {
  font-size: 3.9vw;
  height: 15vw;
}
.selectModal-mob .modalContent .list .listItem {
  font-size: 3.9vw;
  margin: 0 4.6vw;
  margin-bottom: 3vw;
}
.selectModal-mob .modalContent .list .listItem:last-child {
  border-bottom: 0;
}
.errorMsg-mob {
  font-size: 4.6vw !important;
  padding: 3.6vw !important;
}
.loader-mob {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.btn-mob {
  margin: 0 17vw;
  border-radius: 6.9vw;
  font-size: 4.6vw;
  padding: 1vw 4vw;
  margin-bottom: 3.5vw;
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background-color: #f9f4f0;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: #f9f4f0;
  color: #f36a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #f36a5a;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #64d0ae;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper {
  position: relative;
  padding: -5vh;
  min-height: 100vh;
  background: #eeeff3 !important;
  background-size: 120% auto;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.deleteWrapper .tabNav {
  position: relative;
  z-index: 1;
}
.deleteWrapper .tabNav .title {
  padding-top: 3.5vh;
  font-size: 2.6vh;
  font-family: Roboto;
  font-weight: bold;
  text-align: center;
  color: #40424e;
  text-transform: uppercase;
  letter-spacing: 0px;
}
.deleteWrapper .content {
  position: relative;
  z-index: 1;
  height: 85vh;
}
.deleteWrapper .tip {
  font-size: 2.6vh;
  color: #40424e;
  margin: 0.1vh 0;
  font-weight: bold;
  text-align: left;
  padding: 1vw 0;
}
.deleteWrapper .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.3vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.2vh;
  color: #40424e;
  text-align: left;
}
.deleteWrapper .fbtInfo {
  position: relative;
  opacity: 1;
  overflow: hidden;
}
.deleteWrapper .fbtInfo .tip {
  width: 20vw;
  background: #9c8c82;
  padding: 0.8vw 3vw;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.2vw;
  font-family: Roboto;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 0px;
  opacity: 1;
  border-radius: 5vw;
  margin: 0 auto;
}
.deleteWrapper .fbtInfo .block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 18vw;
  margin: 0 auto;
}
.deleteWrapper .fbtInfo .block span {
  display: inline-block;
  width: 2vw;
  height: 2vw;
  background-color: #9c8c82;
}
.deleteWrapper .fbtInfo .blocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55vw;
  margin: 0 auto;
}
.deleteWrapper .fbtInfo .blocks span {
  display: inline-block;
  width: 3vw;
  height: 2vw;
  background-color: #9c8c82;
}
.deleteWrapper .fbtCol {
  position: relative;
  padding-top: 1.4vh;
  padding-bottom: 1.2vh;
  font-size: 2.2vh;
  line-height: 2.5vh;
  font-family: Roboto;
  font-weight: 400;
  color: #40424e;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper .fbtCol .need {
  color: #e84f4f;
}
.deleteWrapper .fbtPhone {
  background: rgba(245, 245, 247, 0);
  padding: 1vh 0;
  opacity: 1;
  border-radius: 8vh;
  background: #fff;
  margin-bottom: 4vh;
}
.deleteWrapper .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.deleteWrapper .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  letter-spacing: 0px;
  margin-left: 2.2vw;
  font-size: 2vh;
  height: 2.2vh;
  line-height: 2.2vh;
  text-align: left;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 85%;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #40424e;
  color: #40424e;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 3.2vh;
  line-height: 3.2vh;
  font-size: 2vh;
  margin-left: 1.1vw;
  border: 0;
  text-align: left;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput:focus {
  outline-color: #fff;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  font-weight: 400;
  line-height: 3.2vh;
  text-align: left;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::placeholder {
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  font-weight: 400;
  line-height: 3.2vh;
  text-align: left;
}
.deleteWrapper .fbtPhone .fpInfo .fpIdCard {
  margin-left: 2.2vw;
  width: 90%;
}
.deleteWrapper .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
}
.deleteWrapper .showTips span {
  font-size: 2vh;
  color: #f36a5a;
}
.deleteWrapper .showTips img {
  margin-left: 2.2vw;
  width: 2vh;
  height: 2vh;
}
.deleteWrapper .fbtTop .fpInfo .fpInput {
  text-align: left;
}
.deleteWrapper .fbtTop .fpInfo .fpInput::-webkit-input-placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper .fbtTop .fpInfo .fpInput::placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper .fbtPhone::-webkit-input-placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper .fbtPhone::placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper .itemSelect {
  padding: 0 2.2vw;
  display: flex;
  margin-bottom: 4vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 8vh;
  opacity: 1;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 2vh;
}
.deleteWrapper .itemSelect .iSelect {
  border: 0;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  height: 3.2vh;
  line-height: 3.2vh;
  color: #391a08;
  width: 100%;
  background: initial;
  text-align: center;
  cursor: pointer;
}
.deleteWrapper .itemSelect .iSelect:focus {
  outline: none;
}
.deleteWrapper .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper .itemSelect .iSelect::-webkit-input-placeholder {
  color: #f36a5a;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect .iSelect::placeholder {
  color: #f36a5a;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect .itemImg {
  position: absolute;
  right: 2.5vw;
  width: 3.2vh;
  height: 2.8vh;
  cursor: pointer;
}
.deleteWrapper .fbtContent {
  width: 100%;
  padding: 1vw 2.2vw;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  background: #fff;
  border-radius: 8vh;
  box-sizing: border-box;
}
.deleteWrapper .fbtContent .fpLabel {
  font-size: 21px;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper .fbtContent .fpText {
  border: 0 !important;
  overflow: hidden;
  font-weight: bold;
  color: #40424e;
  background-color: rgba(245, 245, 247, 0);
  font-family: Roboto;
  caret-color: #40424e;
  text-align: left;
  width: 100%;
  max-height: 4.6vh;
  font-size: 2vh;
}
.deleteWrapper .fbtContent .fpText:focus {
  outline: none;
}
.deleteWrapper .fbtContent .fpText::-webkit-input-placeholder {
  color: #f36a5a;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .fbtContent .fpText::placeholder {
  color: #f36a5a;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect::-webkit-input-placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper .itemSelect::placeholder {
  color: #f36a5a;
  font-weight: 400;
}
.deleteWrapper .fbtContentOn {
  border: 2px solid #ffffff;
  opacity: 1;
}
.deleteWrapper .fbtContentError {
  border: 2px solid #f36a5a;
}
.deleteWrapper .idCard {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper .agreeWrapper {
  margin: 0 auto;
  margin-top: 3.2vh;
  margin-bottom: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.deleteWrapper .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #40424e;
  width: 2.6vh;
  height: 2.6vh;
  margin-left: 8px;
  margin-top: 2px;
}
.deleteWrapper .agreeWrapper .hasCheck {
  margin-left: 8px;
  margin-top: 2px;
  border: 0;
}
.deleteWrapper .agreeWrapper .hasCheck .laImg {
  position: absolute;
  left: 0;
  top: 0;
  height: 2.6vh;
}
.deleteWrapper .agreeWrapper .laText {
  color: #40424e;
  letter-spacing: 0px;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.1vh;
  width: 90%;
  text-align: left;
  cursor: pointer;
}
.deleteWrapper .agreeWrapper .laText .normolColor {
  color: #40424e;
}
.deleteWrapper .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #40424e;
  font-weight: bold;
  padding: 0 0.2vw;
}
.deleteWrapper .fbtAction {
  position: absolute;
  left: 0;
  bottom: 1vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
  background: #64d0ae;
  height: 4.2vw;
  opacity: 0.7;
  font-size: 2.2vw;
  color: #ffffff;
  letter-spacing: 0px;
  margin: 0;
  border-radius: 3vw;
  cursor: pointer;
}
.deleteWrapper .fbtActionOn {
  opacity: 1;
}
.deleteWrapper .fbtSuccess {
  position: relative;
  display: flex;
  height: 90vh;
  z-index: 100;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 4.8vh;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0px;
  color: #64d0ae;
}
.deleteWrapper .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor {
  background-color: #40424e;
  color: #40424e;
  padding-top: 0;
}
.copySuccessTip {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip .iconsmile {
  width: 54px;
  height: 54px;
  fill: #40424e;
  margin-right: 9px;
}
.copySuccessTip span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #40424e;
  opacity: 1;
}
.edit {
  height: auto;
  padding-bottom: 40vh;
}
.list {
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
}
.list .am-list-content {
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 2.1vh !important;
  padding-top: 1.2vh !important;
  padding-bottom: 1.2vh !important;
}
.list .am-radio-input {
  height: 5vw;
}
.list .am-list-item {
  min-height: 4vh !important;
}
.list .am-radio {
  height: 4vh !important;
}
.list .am-radio-inner {
  right: 0.5vh !important;
  top: 2vw !important;
  width: 2.2vh;
  height: 2.2vh;
}
.list .am-radio-inner:after {
  width: 1.2vh;
  height: 2.2vh;
}
.list .listItem {
  text-align: center;
}
.list .listItemTip {
  font-size: 2.1vh;
  color: #999999;
  text-align: center;
}
.list .listItemTip .listItemTSign {
  color: #b92d00;
}
.selectModal {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.modalContent {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 0.9vw;
  background: #EEEFF3;
  margin: 0 3.5vh;
}
.modalContent .listHead {
  background: #40424E;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  height: 10vh;
  line-height: 10vh;
  font-size: 3.2vh;
  font-family: Roboto;
}
.modalContent .listHead .icon {
  width: 35px;
  height: 35px;
}
.modalContent .list {
  position: relative;
  background-color: #EEEFF3;
  overflow: auto;
  text-align: center;
  max-height: 60vh;
  margin-bottom: 1vw;
  padding-top: 2vh;
}
.modalContent .list .listItem {
  position: relative;
  margin: 0 69px;
  text-align: center;
  color: #40424E;
  font-weight: bold;
  cursor: pointer;
  padding: 1.5vh 0;
  background-color: #ffffff;
  border-radius: 5vw;
  margin-bottom: 1vw;
}
.modalContent .list .listItemOn {
  color: #F36A5A;
  font-weight: bold;
}
.modalContent .list .listItemOn .listItem {
  position: relative;
  color: #fb4402;
}
.modalContent .list .listItem:last-child {
  border-bottom: 0;
}
.modalContent .list::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 40px;
}
.modalContent .list::-webkit-scrollbar-button {
  color: #333333;
}
.modalContent .list::-webkit-scrollbar-thumb {
  background-position: center;
  border-radius: 10px;
  height: 40px;
  background-color: blue;
}
.loader {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.errorMsg {
  font-size: 2.2vh;
  padding: 3.3vh;
}
.btn {
  margin: 0 8vh;
  border-radius: 6.9vh;
  font-size: 2.2vh;
  padding: 1vh 4vh;
  margin-bottom: 3.5vh;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
}
#root {
  width: 100%;
}
.commonDatos {
  width: 100vw;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  background: #eeeff3;
}

.fraud-container {
  background: linear-gradient(180deg, #F9D3AD 0%, #F9B5AD 42%, #F9D0AD 100%);
}
.fraud-container .tabs .tab1:hover {
  background-color: rgba(243, 106, 90);
  color: #fff;
}
.fraud-container .tabs .tab2:hover {
  background-color: rgba(243, 106, 90);
  color: #fff;
}
.fraud-container .tabs .check {
  border-bottom: 2px solid rgba(243, 106, 90);
}

.fp-bg {
  background: linear-gradient(180deg, #F9D3AD 0%, #F9B5AD 42%, #F9D0AD 100%);
  top: 0;
  left: 0;
  width: 100vw;
  background-size: cover;
  min-height: 100vh;
}
.fp-bg .bgImg {
  width: 100vw;
  height: 55vw;
  margin-bottom: -2.5vw;
  margin-top: -1vw;
}
.fp-bg .fp-container {
  background: rgba(255, 255, 255);
  border-radius: 28px;
  overflow: hidden;
  width: 90vw;
  margin: 0 auto;
}
.fp-bg .fp-container .title {
  width: 64.8vw;
  height: 11.1vw;
  background: rgba(243, 106, 90);
  margin: 0 auto;
  display: flex;
}
.fp-bg .fp-container .title img {
  height: 6.5vw;
  align-items: center;
  margin: 0 auto;
  margin-top: 2vw;
  /* 设置图片的上边距 */
}
.fp-bg .fp-container .content .desc {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #40424E;
  display: block;
  padding: 3vw;
}
.fp-bg .fp-container .content .phone {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.4vw;
  color: #F36A5A;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-align: center;
  letter-spacing: 1px;
  margin-top: -2vw;
}
.fp-bg .fp-container .content .phone img {
  display: inline-block;
  width: 6vw;
  height: 4vw;
  margin-right: 1.5vw;
  align-content: center;
  margin-bottom: 1vw;
  image-rendering: pixelated;
  border-radius: 0.5vw;
}
.fp-bg .fp-container .content .desc1 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #40424E;
  text-align: center;
  margin-bottom: 1vw;
  padding: 3vw;
}
.fp-bg .fp-container .content .desc2 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #F36A5A;
  text-align: center;
  margin: 1vw 0 3vw 0;
}
.fp-bg .fp-container .content .guideImg {
  display: block;
  width: 70vw;
  height: 60vw;
  margin: 0 auto;
}
.fp-bg .footer {
  height: 2vw;
  margin-top: 5vw;
}
.fp-bg .copy-tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3vw;
  border-radius: 4px;
  font-size: 5vw;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.fp-bg {
  background: linear-gradient(180deg, #F9D3AD 0%, #F9B5AD 42%, #F9D0AD 100%);
  top: 0;
  left: 0;
  width: 100vw;
  background-size: cover;
  min-height: 100vh;
}
.fp-bg .bgImg {
  width: 100vw;
  height: 55vw;
  margin-bottom: -2.5vw;
  margin-top: -1vw;
}
.fp-bg .fp-container {
  background: rgba(255, 255, 255);
  border-radius: 28px;
  overflow: hidden;
  width: 90vw;
  margin: 0 auto;
}
.fp-bg .fp-container .title {
  width: 64.8vw;
  height: 11.1vw;
  background: rgba(243, 106, 90);
  margin: 0 auto;
  display: flex;
}
.fp-bg .fp-container .title img {
  height: 6.5vw;
  align-items: center;
  margin: 0 auto;
  margin-top: 2vw;
  /* 设置图片的上边距 */
}
.fp-bg .fp-container .content .desc {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #40424E;
  display: block;
  padding: 3vw;
}
.fp-bg .fp-container .content .phone {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.4vw;
  color: #F36A5A;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-align: center;
  letter-spacing: 1px;
  margin-top: -2vw;
}
.fp-bg .fp-container .content .phone img {
  display: inline-block;
  width: 6vw;
  height: 4vw;
  margin-right: 1.5vw;
  align-content: center;
  margin-bottom: 1vw;
  image-rendering: pixelated;
  border-radius: 0.5vw;
}
.fp-bg .fp-container .content .desc1 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #40424E;
  text-align: center;
  margin-bottom: 1vw;
  padding: 3vw;
}
.fp-bg .fp-container .content .desc2 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #F36A5A;
  text-align: center;
  margin: 1vw 0 3vw 0;
}
.fp-bg .fp-container .content .guideImg {
  display: block;
  width: 70vw;
  height: 60vw;
  margin: 0 auto;
}
.fp-bg .footer {
  height: 2vw;
  margin-top: 5vw;
}
.fp-bg .copy-tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3vw;
  border-radius: 4px;
  font-size: 5vw;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.phoneLost-container {
  background: linear-gradient(180deg, #A0BDFF 0%, #055CFF 100%);
}
.phoneLost-container .tabs .tab1:hover {
  background-color: #64d0ae;
  color: #fff;
}
.phoneLost-container .tabs .tab2:hover {
  background-color: #64d0ae;
  color: #fff;
}
.phoneLost-container .tabs .check {
  border-bottom: 2px solid #64d0ae;
}

.pl-bg {
  background: linear-gradient(180deg, #A0BDFF 0%, #055CFF 100%);
  top: 0;
  left: 0;
  width: 100vw;
  background-size: cover;
  min-height: 100vh;
  margin-top: 0;
  position: relative;
}
.pl-bg .bgImg {
  display: block;
  margin: 0 auto;
  width: 85vw;
  height: 73vw;
  margin-bottom: 0vw;
}
.pl-bg .pl-container {
  background: rgba(255, 255, 255);
  border-radius: 2.8vw 2.8vw 0 0;
  overflow: hidden;
  width: 90vw;
  margin: 0 auto;
  margin-top: 3vw;
}
.pl-bg .pl-container .title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.4vw;
  color: #64D0AE;
  letter-spacing: 0px;
  padding-left: 4vw;
  padding-top: 4vw;
}
.pl-bg .pl-container .content .desc {
  font-family: Roboto;
  font-weight: 400;
  font-size: 4.4vw;
  color: #40424E;
  display: block;
  padding: 2vw 10vw 0vw 4vw;
}
.pl-bg .pl-container .content .guideImg {
  display: block;
  width: 70vw;
  height: 51vw;
  margin: 4vw auto;
}
.pl-bg .segundo-container {
  background: rgba(255, 255, 255);
  border-radius: 0 0 2.8vw 2.8vw;
  overflow: hidden;
  width: 90vw;
  margin: 0 auto;
  margin-top: 3vw;
}
.pl-bg .segundo-container .title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.4vw;
  color: #64D0AE;
  letter-spacing: 0px;
  padding-left: 4vw;
  padding-top: 4vw;
}
.pl-bg .segundo-container .desc {
  font-family: Roboto;
  font-weight: 400;
  font-size: 4.4vw;
  color: #40424E;
  display: block;
  padding: 2vw 10vw 0vw 4vw;
}
.pl-bg .segundo-container .phone-selection {
  background: #17644C;
  border-radius: 28vw;
  width: 70vw;
  display: flex;
  height: 12vw;
  justify-content: center;
  align-items: center;
  margin: 3vw auto;
}
.pl-bg .segundo-container .phone-selection img {
  width: 5.6vw;
  height: 5.6vw;
  margin-right: 2vw;
}
.pl-bg .segundo-container .phone-selection span {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.5vw;
  color: #FFFFFF;
  letter-spacing: 1px;
}
.pl-bg .segundo-container .detail-selection {
  padding: 2vw 0 2vw 4vw;
}
.pl-bg .segundo-container .detail-selection .detail-title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.5vw;
  color: #40424E;
  margin-bottom: 1vw;
}
.pl-bg .segundo-container .detail-selection .detail-desc p {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.5vw;
  color: #40424E;
  letter-spacing: 0px;
  margin-bottom: 0.5vw;
}
.pl-bg .segundo-container .detail-selection .detail-desc .small {
  font-family: Roboto;
  font-weight: 400;
  font-size: 2.8vw;
  color: #40424E;
  letter-spacing: 0.1px;
  margin-bottom: 0.5vw;
}
.pl-bg .segundo-container .card-selection {
  padding: 0 4vw;
}
.pl-bg .segundo-container .card-selection p {
  font-family: Roboto;
  font-weight: 400;
  font-size: 3.3vw;
  color: #64D0AE;
  letter-spacing: 0px;
}
.pl-bg .segundo-container .card-selection img {
  width: 81.5vw;
  height: 47.6vw;
  display: block;
  margin: 2.8vw auto;
}
.pl-bg .footer {
  height: 2vw;
  margin-top: 5vw;
}
.pl-bg .copy-tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -200%);
          transform: translate(-50%, -200%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3vw;
  border-radius: 4px;
  font-size: 5vw;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.pl-bg {
  background: linear-gradient(180deg, #A0BDFF 0%, #055CFF 100%);
  top: 0;
  left: 0;
  width: 100vw;
  background-size: cover;
  min-height: 100vh;
  margin-top: 0;
  position: relative;
}
.pl-bg .bgImg {
  display: block;
  margin: 0 auto;
  width: 85vw;
  height: 73vw;
  margin-bottom: 0vw;
}
.pl-bg .pl-container {
  background: rgba(255, 255, 255);
  border-radius: 2.8vw 2.8vw 0 0;
  overflow: hidden;
  width: 90vw;
  margin: 0 auto;
  margin-top: 3vw;
}
.pl-bg .pl-container .title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.4vw;
  color: #64D0AE;
  letter-spacing: 0px;
  padding-left: 4vw;
  padding-top: 4vw;
}
.pl-bg .pl-container .content .desc {
  font-family: Roboto;
  font-weight: 400;
  font-size: 4.4vw;
  color: #40424E;
  display: block;
  padding: 2vw 10vw 0vw 4vw;
}
.pl-bg .pl-container .content .guideImg {
  display: block;
  width: 70vw;
  height: 51vw;
  margin: 4vw auto;
}
.pl-bg .segundo-container {
  background: rgba(255, 255, 255);
  border-radius: 0 0 2.8vw 2.8vw;
  overflow: hidden;
  width: 90vw;
  margin: 0 auto;
  margin-top: 3vw;
}
.pl-bg .segundo-container .title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.4vw;
  color: #64D0AE;
  letter-spacing: 0px;
  padding-left: 4vw;
  padding-top: 4vw;
}
.pl-bg .segundo-container .desc {
  font-family: Roboto;
  font-weight: 400;
  font-size: 4.4vw;
  color: #40424E;
  display: block;
  padding: 2vw 10vw 0vw 4vw;
}
.pl-bg .segundo-container .phone-selection {
  background: #17644C;
  border-radius: 28vw;
  width: 70vw;
  display: flex;
  height: 12vw;
  justify-content: center;
  align-items: center;
  margin: 3vw auto;
}
.pl-bg .segundo-container .phone-selection img {
  width: 5.6vw;
  height: 5.6vw;
  margin-right: 2vw;
}
.pl-bg .segundo-container .phone-selection span {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.5vw;
  color: #FFFFFF;
  letter-spacing: 1px;
}
.pl-bg .segundo-container .detail-selection {
  padding: 2vw 0 2vw 4vw;
}
.pl-bg .segundo-container .detail-selection .detail-title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.5vw;
  color: #40424E;
  margin-bottom: 1vw;
}
.pl-bg .segundo-container .detail-selection .detail-desc p {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.5vw;
  color: #40424E;
  letter-spacing: 0px;
  margin-bottom: 0.5vw;
}
.pl-bg .segundo-container .detail-selection .detail-desc .small {
  font-family: Roboto;
  font-weight: 400;
  font-size: 2.8vw;
  color: #40424E;
  letter-spacing: 0.1px;
  margin-bottom: 0.5vw;
}
.pl-bg .segundo-container .card-selection {
  padding: 0 4vw;
}
.pl-bg .segundo-container .card-selection p {
  font-family: Roboto;
  font-weight: 400;
  font-size: 3.3vw;
  color: #64D0AE;
  letter-spacing: 0px;
}
.pl-bg .segundo-container .card-selection img {
  width: 81.5vw;
  height: 47.6vw;
  display: block;
  margin: 2.8vw auto;
}
.pl-bg .footer {
  height: 2vw;
  margin-top: 5vw;
}
.pl-bg .copy-tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -200%);
          transform: translate(-50%, -200%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3vw;
  border-radius: 4px;
  font-size: 5vw;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.cupones-container {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  background: rgba(199, 40, 44);
  background-image: url(../../static/media/bg.02a37596.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100vw;
}
.cupones-container .title-img {
  margin: 10.7vw 0 0 7.4vw;
  width: 71.6vw;
  height: 19.1vw;
}
.cupones-container .cupone-main {
  margin: 6vw auto;
}
.cupones-container .cupone-main .title {
  width: 82vw;
  background: linear-gradient(180deg, #FDAE43 0%, #FF9C53 100%);
  border-radius: 6vw;
  height: 14vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.cupones-container .cupone-main .title p {
  text-align: center;
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.9vw;
  color: #FFFFFF;
  letter-spacing: 0px;
  margin-bottom: 0;
  padding: 6vw;
}
.cupones-container .cupone-main .desc-selection {
  width: 82vw;
  margin: 0 auto;
  background: rgba(255, 246, 216);
  border-radius: 6vw;
  padding: 4vw;
}
.cupones-container .cupone-main .desc-selection span {
  display: block;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  font-size: 3.3vw;
  color: #40424E;
  letter-spacing: 0px;
  margin-bottom: 3vw;
}
.cupones-container .cupone-main .desc-selection span img {
  width: 3.4vw;
  height: 3.4vw;
}
.cupones-container .cupone-main .desc-selection .guideImg {
  display: block;
  margin: 0 auto;
  width: 63.1vw;
  height: 50.9vw;
}
.cupones-container .bottom-desc1 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.9vw;
  color: #FFFFFF;
  letter-spacing: 0px;
  text-align: center;
  width: 82vw;
  margin: 0 auto;
}
.cupones-container .bottom-desc2 {
  font-family: Roboto;
  font-weight: 400;
  font-size: 3.3vw;
  color: #FFFFFF;
  letter-spacing: 0px;
  text-align: center;
  width: 82vw;
  margin: 3vw auto 5vw auto;
}

